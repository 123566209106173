import type { Section } from '@src/interfaces';
import React from 'react';
import Link from 'next/link';
import stripUrlPlaceholder from '@src/utils/strip-url-placeholder';
import RegionComponent from 'src/components/SectionComponent/Region';

const SectionComponent = ({
  regions,
  columnWidths,
  title,
  link,
}: Section) => (
  <section className="section row">
    {title ? <div className="col-12"><h2 className="component-title section-title">
      {link ? (
        <Link as={stripUrlPlaceholder(link)} href="/">
          <a className="teaser-anchor">{title}</a>
        </Link>
        ) : title}
    </h2></div> : null}

    {regions.map((region, index) => (
      <RegionComponent
        key={`r${index}`}
        width={columnWidths.split('-')[region.position - 1]}
        {...region}
      />
    ))}
  </section>
);

export default SectionComponent;
