import React from 'react';
import SvgImage from '@src/components/SvgImage';
import { Models } from '@data/et-web-api';
import { trackEvent } from '@src/utils/et';
import styles from './styles.module.scss';

const links = [
  {
    icon: 'logo-joyn',
    href: 'https://www.joyn.at',
    label: 'Joyn',
  },
  {
    icon: 'logo-atv',
    href: 'https://www.atv.at',
    label: 'ATV',
  },
  {
    icon: 'logo-puls4',
    href: 'https://www.puls4.com',
    label: 'Puls 4',
  },
  {
    icon: 'logo-wettercom',
    href: 'https://www.wetter.com',
    label: 'wetter.com',
  },
];

const onClickLogo = (label: string, url: string) => {
  const eventName: Models.LinkoutClick['eventName'] = 'Linkout Click';
  const eventPayload: Models.LinkoutClick['eventPayload'] = {
    linkoutLabel: label,
    targetUrl: url,
  }
  trackEvent(eventName, eventPayload);
};

const Superbar = () => (
  <div className={styles.superbar}>
    <div className="container">
      <div className={styles.wrapper}>
        {links.map((link, index) => (
          <a
            key={index}
            aria-label={link.label}
            className={styles.link}
            href={link.href}
            onClick={() => onClickLogo(link.label, link.href.replace('https://', ''))}
            rel="noopener noreferrer"
            target="_blank"
          >
            <SvgImage className={styles.logo} reference={link.icon} title={link.label} />
          </a>
        ))}
      </div>
    </div>
  </div>
);
export default Superbar;
