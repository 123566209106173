import type { FC, ReactNode } from 'react';
import type {ContentRoute, MenuTree} from '@src/interfaces';
import React, { useState, useEffect } from 'react';
import uniqid from 'uniqid';
import StickyPlayer from '@src/components/OasisPlayer/StickyPlayer';
import Navigation from '@src/components/Navigation';
import { enableWithVendors } from '@src/utils/et';
import FullBanner from '@src/components/Ad/FullBanner';
import Skyscraper from '@src/components/Ad/Skyscraper';
import Footer from '@src/components/Footer/Footer';
import CmpBanner from '@src/components/cmp/CmpBanner';
import LazySurveyDialog from '@src/components/survey/LazySurveyDialog';
import RouteContext, { ContextRoute } from '@src/components/Layout/route-context';
import OasisPlayerStore from '@src/components/OasisPlayer/OasisPlayerStore';
import Superbar from '@src/components/Superbar';
import { useRouter } from 'next/router';

export type DefaultLayoutProps = {
  route: ContentRoute;
  menus: {
    [k: string]: MenuTree;
  };
  socials: Record<string, string>;
  children?: ReactNode,
};

type adIDsType = {
  fullbanner: string;
  fullbannerMobile: string;
  skyscraper: string;
};

const DefaultLayout: FC<DefaultLayoutProps> = ({
  route,
  menus,
  socials,
  children,
}) => {
  const { layout, meta } = route;
  const [routeContext, setRouteContext] = useState<ContextRoute>({ layout, meta });
  const [isOverlayOpen, setIsOverlayOpen] = useState<boolean>(false);
  const [adIDs, setAdIDs] = useState<adIDsType>({} as adIDsType);

  useEffect(() => {
    setAdIDs({
      fullbanner: uniqid(),
      fullbannerMobile: uniqid(),
      skyscraper: uniqid(),
    });
  }, []);

  useEffect(() => {
    setRouteContext({ layout, meta });
  }, [layout, meta]);

  return (
    <RouteContext.Provider value={routeContext}>
      <OasisPlayerStore>
        <StickyPlayer />
        <Superbar />
        <Navigation
          menu={menus.main}
          onOverlayToggle={setIsOverlayOpen}
          socials={socials}
          topics={menus.topics}
          trending={menus.trending}
        />

        <div aria-hidden={isOverlayOpen}>
          {route.meta.subtitle?.toLowerCase() !== 'home' && (
            <div className={`fullbanner-wrapper${layout ? ` layout-${layout}` : ''}`}>
              <div className="container">
                <FullBanner idDesktop={adIDs.fullbanner} idMobile={adIDs.fullbannerMobile} />
              </div>
            </div>
          )}

          <main className={`main ${layout ? `layout-${layout}` : ''}`} id="main-content">
            <div className="container main-content">
              {children}

              <Skyscraper id={adIDs.skyscraper} />
            </div>
          </main>

          <Footer menu={menus.footer} />

          {!useRouter().asPath.includes('nocmp=1') && <CmpBanner
            blurred
            initiallyHidden
            onInitialize={vendors => enableWithVendors(vendors)}
          />}

          <LazySurveyDialog />
        </div>
      </OasisPlayerStore>
    </RouteContext.Provider>
  );
};

export default DefaultLayout;
