import type { FC } from 'react';
import React from 'react';

type SvgImageProps = {
  title?: string;
  reference: string;
  ariaHidden?: boolean;
  className?: string;
};

const SvgImage: FC<SvgImageProps> = ({
  title,
  reference,
  ariaHidden,
  className,
}) => (
  <svg aria-hidden={ariaHidden} className={className}>
    {title ? <title>{title}</title> : null}
    <use xlinkHref={`/assets/icons/sprite.svg?v=3#${reference}`} />
  </svg>
);

export default SvgImage;
