import React, { useEffect } from 'react';
import { NextPageContext } from 'next';
import Head from 'next/head';
import Router, {useRouter} from 'next/router';
import {ContentRoute, MenuTree, MinimalTeaser} from '@src/interfaces';
import MetaTags from '@src/components/MetaTags';
import { setContext, trackEvent, trackPage } from '@src/utils/et';
import useOnthe from '@src/utils/onthe';
import DefaultLayout from '@src/components/Layout/DefaultLayout';
import SectionComponent from '@src/components/SectionComponent';
import fetchMenu from '@src/utils/api/fetch-menu';
import fetchRoute from '@src/utils/api/fetch-route';
import fetchDiscoveryLinks from "@src/utils/api/fetch-discovery-links";
import {Models} from "@data/et-web-api";
import useLogin from '@src/components/auth/use-login';
import DiscoveryLinksContext from '@src/components/DiscoveryLinks/DiscoveryLinksContext';
import TabsSection from 'src/components/TabsSection';

export interface IndexProps {
  route: ContentRoute;
  menus: {
    [k: string]: MenuTree;
  };
  socials: Record<string, string>;
  discoveryLinks: MinimalTeaser[];
}

const IndexPage = ({ route, menus, socials, discoveryLinks }: IndexProps) => {
  const { title, meta } = route;
  const router = useRouter();
  const { userToken } = useLogin();

  useEffect(() => {
    if (userToken) {
      setContext({miraToken: userToken.miratoken});
    }

    if (route) {
      trackPage(route.meta.coralContext);
    }

    if (router.asPath.includes('?auth=login')) {
      const eventName: Models.AuthenticationSuccess['eventName'] = 'Authentication Success';
      trackEvent(eventName, {});
    }
  }, [route, router, userToken]);


    useOnthe(route?.meta.ioConfig, !router.asPath.includes('noontheio=1'));


  return (
    <>
      <Head>
        <title>{title}</title>
        {(route.meta.coralContext.pageType === 'article' || route.meta.coralContext.screenName === 'Home') &&
          <link href="/rssfeed" rel="alternate" type="application/rss+xml" />
        }
      </Head>

      <MetaTags {...meta} />
      <DiscoveryLinksContext.Provider value={discoveryLinks}>
        {/* TODO: Move layout to _app.tsx when fixing routes */}
        <DefaultLayout
          menus={menus}
          route={route}
          socials={socials}
        >
          {route.sections?.map((section, index) => {
            switch (section.type) {
              case 'tabs':
                return <TabsSection key={`s${index}`} {...section}/>
              default:
                return <SectionComponent key={`s${index}`} {...section} />
            }
          })}
        </DefaultLayout>
      </DiscoveryLinksContext.Provider>
    </>
  );
};

IndexPage.getInitialProps = async ({ asPath, res }: NextPageContext) => {
  // Redirect to https://app.puls24.at/something from '/app-redirect/something'.
  if (asPath && /^\/app-redirect\/?.*/i.test(asPath)) {
    const appUrl= `https://app.puls24.at${  asPath.replace('/app-redirect', '')}`;
    if (typeof window !== 'undefined') {
      window.location.href = appUrl;
      // While the page is loading, code execution will
      // continue, so we'll await a never-resolving
      // promise to make sure our page never
      // gets rendered.
      await new Promise(() => {
        //
      })
    }
    else if (res) {
      res.writeHead(301, {
        Location: appUrl,
      });
      res.end();
    }
    return {};
  }

  const [route, main, topics, trending, footer] = await Promise.all([
    fetchRoute(asPath, res),
    fetchMenu('main'),
    fetchMenu('topics'),
    fetchMenu('trending'),
    fetchMenu('footer'),
  ]);

  const discoveryLinks = await fetchDiscoveryLinks()

  if (route.type === 'redirect') {
    const location = route.location.replace(/^\/[a-z\d]+/, '');
    if (res) {
      res.writeHead(route.status, {
        Location: location,
      });
      res.end();
    } else {
      Router.replace('/', location).catch(reason => {
        console.error('Could not follow redirect to', location, reason);
      });
      return {};
    }
  } else if (res && route.status) {
    res.statusCode = route.status;
  }

  return {
    route,
    menus: { main, topics, trending, footer },
    discoveryLinks,
  };
};

export default IndexPage;
