import React, { useState } from 'react';
import Navigation, { NavigationProps } from '@src/components/Navigation/Navigation';
import FollowUs from '@src/components/SocialButtons/FollowUs';
import dayjs from 'dayjs';
import SvgImage from '@src/components/SvgImage';
import Link from 'next/link';

const Footer = (props: Pick<NavigationProps, 'menu'>) => {

  const [isMenuExpanded, setIsMenuExpanded] = useState(true);

  return (
    <footer className={`footer ${isMenuExpanded ? 'menu-expanded' : ''}`}>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='row'>
              <div className='col-lg-3 logo-group'>
                <Link as="/" href="/" title="Home">
                  <a><SvgImage className='logo' reference='logo' title='Home'/></a>
                </Link>

                <span className='copyright'>{dayjs().year()} © PULS 4 TV GmbH &amp; Co KG</span>

                <div className='funding'>Diese Website wird unterstützt durch Mittel aus den
                  kulturellen Einrichtungen der Verwertungsgesellschaft Rundfunk.
                </div>

                <span className="links"><a href="/datenschutz">Datenschutz</a>, <a href="/anb">ANB</a>, <a href="/impressum">Impressum</a></span>

                <FollowUs />
              </div>

              <div className="col-lg-7 ml-lg-5">
                <Navigation firstLevelHeadline group='footer' menu={props.menu} />
              </div>

            </div>
          </div>
        </div>
      </div>

      <div className="expand-menu">
        <button aria-label="Expand / Collapse" onClick={() => setIsMenuExpanded(!isMenuExpanded)} type="button">
          <SvgImage reference="chevron-down" title="Expand / Collapse" />
        </button>
      </div>

    </footer>
  );
};

export default Footer;
