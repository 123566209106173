import type { FC, MouseEventHandler, KeyboardEvent } from 'react';
import type { Teaser } from '@src/interfaces';
import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import Link from 'next/link';
import stripUrlPlaceholder from '@src/utils/strip-url-placeholder';
import getFormattedDate from '@src/utils/get-formatted-date';
import SvgImage from '@src/components/SvgImage';
import ShareButton from '@src/components/SocialButtons/ShareButton';
import {trackEvent} from "@src/utils/et";
import styles from './styles.module.scss';

type ExpandableTeaserProps = Teaser & {
  contentBox?: string;
  expanded?: boolean;
  focusOnRender?: boolean;
  fadeIn?: boolean;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  onToggle?: () => void;
  className?: string;
}

const ExpandableTeaser: FC<ExpandableTeaserProps> = ({
  title,
  url,
  teaserText,
  contentBox,
  advertorial,
  updated,
  expanded = false,
  focusOnRender,
  fadeIn,
  onClick,
  onToggle,
  className = '',
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(expanded);
  const triggerEl = useRef<HTMLButtonElement>(null);

  const vrContentbox = contentBox ? {
    'data-vr-contentbox': contentBox,
    'data-vr-contentbox-url': url,
  } : {};
  const vrHeadline = contentBox ? { 'data-vr-headline': '' } : {};

  const handleClose = (event: KeyboardEvent<HTMLImageElement>) => {
    if (event.key === 'Escape') {
      setIsExpanded(false);
      if (triggerEl.current) {
        triggerEl.current.focus();
      }

      if (onToggle) {
        onToggle();
      }
    }
  };

  const handleToggle = () => {
    setIsExpanded(!isExpanded);

    trackEvent(isExpanded ? 'Close Accordion Click' : 'Open Accordion Click', {
      accordionLabel: title,
    })

    if (onToggle) {
      onToggle();
    }
  };

  useEffect(() => {
    if (triggerEl.current && focusOnRender) {
      triggerEl.current.focus();
    }
  }, [focusOnRender]);

  useEffect(() => {
    setIsExpanded(expanded);
  }, [expanded]);

  return (
    <div className={classnames(styles.container, {
        [className]: className,
        [styles.fadeIn]: fadeIn,
      })}
      onKeyDown={handleClose}
    >
      <button
        ref={triggerEl}
        aria-expanded={isExpanded}
        className={styles.trigger}
        onClick={handleToggle}
        type="button"
      >
        <div className={styles.titleContainer}>
          <h3
            className={styles.title}
            {...vrHeadline}
          >
            {title}
          </h3>

          {advertorial ? <div className={styles.meta}>Anzeige</div> : null}

          {updated ? <div className={styles.meta} suppressHydrationWarning>{getFormattedDate(updated)}</div> : null}
        </div>

        <div className={styles.iconContainer}>
          <SvgImage
            ariaHidden
            className={
              classnames(styles.icon, {
                [styles.open]: isExpanded,
              })
            }
            reference="close"
          />
        </div>
      </button>

      <div
        aria-hidden={!isExpanded}
        className={classnames(styles.content, {
          [styles.contentHidden]: !isExpanded,
        })}
      >
        <p className={styles.contentText}>{teaserText}</p>

        <Link as={stripUrlPlaceholder(url)} href="/">
          <a
            className={styles.anchor}
            onClick={onClick}
            title={title}
            {...vrContentbox}
          >
            <SvgImage ariaHidden reference="chevron-right" />
            Zum Artikel
          </a>
        </Link>

        <ShareButton className={styles.share} label={title} url={stripUrlPlaceholder(url)} />

      </div>
    </div>
  );
};

export default ExpandableTeaser;
