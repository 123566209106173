import { useEffect } from 'react';
import getConfig from 'next/config';
import { loadScript } from '@src/utils/scripts';
import { IoConfig } from '@src/interfaces';

declare global {
  interface Window {
    '_io_config': {
      ['0.2.0']: IoConfig[];
    };
  }
}

const useOnthe = (config?: IoConfig, enabled?: boolean) => {
  useEffect(() => {
    const {
      publicRuntimeConfig: { ONTHEIO_ID },
    } = getConfig();
    // eslint-disable-next-line no-underscore-dangle
    window._io_config = window._io_config || {};
    // eslint-disable-next-line no-underscore-dangle
    window._io_config['0.2.0'] = window._io_config['0.2.0'] || [];
    if (!ONTHEIO_ID || !enabled) {
      return;
    }
    loadScript(`https://cdn.onthe.io/io.js/${ONTHEIO_ID}`).catch(reason =>
      console.warn('Could not load io:', reason),
    );
  }, [enabled]);

  useEffect(() => {
    if (config) {
      // eslint-disable-next-line no-underscore-dangle
      window._io_config['0.2.0'].push(config);
    }
  }, [config]);
};

export default useOnthe;
