import React, { useContext, useEffect, useState } from 'react';
import { SomtagComponent } from '@src/interfaces';
import { AdsContext } from '@src/context/ads';
import useSometagClientSlot from '@src/utils/hooks/useSometagClientSlot';
import { useRouter } from 'next/router';
import classnames from 'classnames';

export interface SomtagProps extends SomtagComponent {
  className?: string;
  adContentHeight?: number;
  reload?: number;
  routeAware?: boolean;
}

const Somtag = ({
  adContentHeight,
  className,
  display,
  id,
  reload,
  routeAware = false,
  slot,
}: SomtagProps) => {
  const router = useRouter();
  const { adsEnabled, somtagInsert, somtagReload } = useContext(AdsContext);
  const adSlot = useSometagClientSlot(slot, display);
  const [containerId, setContainerId] = useState<string>('');
  const [adHeight, setAdHeight] = useState<number>(adContentHeight || 0);
  const isAdsEnabled = adsEnabled && !router.asPath.includes('noads=1');

  useEffect(() => {
      if (routeAware) {
        // ID must start with a letter to prevent querySelector error
        setContainerId(`ad_${id}_${router.asPath.replace(/[^a-zA-Z0-9]/g, '').substring(0, 32)}`);
      } else {
        setContainerId(`ad_container-${adSlot}-${id}`);
      }
  }, [adSlot, id, routeAware, router.asPath]);

  useEffect(() => {
    if (containerId && adSlot) {
      somtagInsert(adSlot, containerId, setAdHeight);
    }
  }, [somtagInsert, containerId, adSlot]);

  useEffect(() => {
    if (!reload || !adSlot) {
      return () => null;
    }

    const interval = setInterval(
      () => somtagReload(adSlot),
      reload,
    );

    return () => {
      clearInterval(interval);
    };
  }, [adSlot, reload, somtagReload]);

  if (!isAdsEnabled || !adSlot) {
    return null;
  }

  return (
    <>
      <aside
        key={containerId}
        className={classnames(`ad-component-wrapper ad-component-${adSlot}`, {
          [className as string]: !!className,
        })}
        id={containerId}
        style={{ height: `${adHeight}px` }}
        title="Werbung"
      />

      {(adSlot === 'inread1' || adSlot === 'minread1') && (
        <aside className="ad-inread-wrapper" id="71M_inreadads" title="Werbung" />
      )}
    </>
  );
};

export default Somtag;
